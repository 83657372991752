import { NavItemType } from 'types';
import managerView, { agentView, clientView, agencyView } from './sidebarItems';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [managerView, agentView, clientView, agencyView]
};

export default menuItems;
