import { createSlice } from '@reduxjs/toolkit';
import { PropertiesDetailsType } from './types';

export const initialState: PropertiesDetailsType = {
    documentList: [],
    isLoading: false,
};

const PropertyDetails = createSlice({
    name: 'propertyDetailsState',
    initialState,
    reducers: {
        setDocumentList(state, action) {
            state.documentList = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        }
    }
});

export const { actions: PropertyDetailsReducerAction, name: PropertyDetailsReducerName, reducer: PropertyDetailsReducer } = PropertyDetails;
