import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state: any) => state.AgencyState || initialState;

export const agenciesListSelector = createSelector(selectDomain, (state) => state.agenciesList);
export const agenciesListLoadingSelector = createSelector(selectDomain, (state) => state.isLoading);
export const pageSelector = createSelector(selectDomain, (state) => state.page);
export const isDeleteModalOpenSelector = createSelector(selectDomain, (state) => state.isDeleteModalOpen);
export const selectedAgencySelector = createSelector(selectDomain, (state) => state.selectedAgency);
export const pageSizeSelector = createSelector(selectDomain, (state) => state.pageSize);
export const totalSelector = createSelector(selectDomain, (state) => state.total);
export const sortBySelector = createSelector(selectDomain, (state) => state.sortBy);
export const propertiesListByAgencySelector = createSelector(selectDomain, (state) => state.propertiesList);
export const agentsListByAgencySelector = createSelector(selectDomain, (state) => state.agentsList);
