import { call, put } from 'redux-saga/effects';
import { createSliceSaga } from 'store/saga-helper';
import { AxiosResponse } from 'axios';
import { PropertyDetailsReducerAction } from './slice';
import { createPropertyDocument, deletePropertyDocument, getPropertyDetailDocuments } from 'services/property/propertiesDetails';
import { DocumentListType } from './types';
import { SnackbarReducerAction } from 'store/snackbar/slice';

const saga = createSliceSaga({
    name: 'property-detail-state',
    caseSagas: {
        *getPropertyDocuments(action: { payload: { propertyId: string; unit_ids: string } }) {
            try {
                console.log(action.payload, 'get proeprty docs');
                yield put(PropertyDetailsReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(getPropertyDetailDocuments, action.payload);
                if (response?.status === 200) {
                    yield put(PropertyDetailsReducerAction.setDocumentList([]));
                    const responseData = response?.data?.data?.map((item: DocumentListType) => {
                        return {
                            ...item,
                            unit_name: item?.unit_ids?.unit_name ?? '',
                            property_name: item?.property_id?.property_name ?? ''
                        };
                    });
                    yield put(PropertyDetailsReducerAction.setDocumentList(responseData));
                }
            } catch (error) {
                console.log('Error in Properties API', error);
                throw new Error();
            } finally {
                yield put(PropertyDetailsReducerAction.setIsLoading(false));
            }
        },
        *addPropertyDocuments(action: { payload: any }) {
            try {
                const response: AxiosResponse = yield call(createPropertyDocument, action.payload);
                if (response?.status === 200) {
                    yield put(
                        PropertyDetailsSagaActions.getPropertyDocuments({
                            propertyId: response.data.data.property_id,
                            unit_ids: response.data.data.unit_ids
                        })
                    );
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: "Documents Added Successfully"
                        })
                    );
                }
            } catch (error) {
                console.log('Error in Properties API', error);
                throw new Error();
            }
        },
        *deletePropertyDocuments(action: { payload: { id: string; propertyId: string } }) {
            try {
                yield put(PropertyDetailsReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(deletePropertyDocument, action.payload.id);
                if (response?.status === 200) {
                    yield put(
                        PropertyDetailsSagaActions.getPropertyDocuments({
                            propertyId: response.data.data.property_id,
                            unit_ids: action.payload.id !== response.data.data.property_id && response.data.data.unit_ids
                        })
                    );
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: "Document Deleted Successfully"
                        })
                    );
                }
            } catch (error) {
                console.log('Error in Properties API', error);
                throw new Error();
            } finally {
                yield put(PropertyDetailsReducerAction.setIsLoading(false));
            }
        }
    },
    sagaType: 'takeEvery'
});

export const { actions: PropertyDetailsSagaActions, saga: PropertyDetailsSaga, name: PropertyDetailsSagaName } = saga;
