import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state: any) => state.clientsState || initialState;

export const ClientsListSelector = createSelector(selectDomain, (state) => state.clientsList);
export const selectedClientSelector = createSelector(selectDomain, (state) => state.selectedClient);
export const isLoadingSelector = createSelector(selectDomain, (state) => state.isLoading);
export const isDeleteClientLoadingSelector = createSelector(selectDomain, (state) => state.isDeleteClientLoadingSelector);
export const isDeleteModalOpenSelector = createSelector(selectDomain, (state) => state.isDeleteModalOpen);
export const countriesListSelector = createSelector(selectDomain, (state) => state.countries);
export const pageSelector = createSelector(selectDomain, (state) => state.page);
export const pageSizeSelector = createSelector(selectDomain, (state) => state.pageSize);
export const totalSelector = createSelector(selectDomain, (state) => state.total);
export const sortBySelector = createSelector(selectDomain, (state) => state.sortBy);
export const clientFinancialsSelector = createSelector(selectDomain, (state) => state.clientFinancials);
export const financialsPageSelector = createSelector(selectDomain, (state) => state.financialsPage);
export const financialsPageSizeSelector = createSelector(selectDomain, (state) => state.financialsPageSize);
export const financialsTotalSelector = createSelector(selectDomain, (state) => state.financialsTotal);
