import { createSlice } from '@reduxjs/toolkit';
import { agencyDetailsType, agencyType } from './types';

export const initialState: agencyDetailsType = {
    agenciesList: [],
    isLoading: false,
    selectedAgency: {} as agencyType,
    propertiesList: [],
    agentsList: [],
    isDeleteModalOpen: false,
    page: 1,
    pageSize: 12,
    total: 0,
    sortBy: 'createdAt'
};

const agencies = createSlice({
    name: 'AgencyState',
    initialState,
    reducers: {
        setAgenciesList(state, action) {
            state.agenciesList = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setSelectedAgency(state, action) {
            state.selectedAgency = action.payload;
        },
        setToggleDeleteModal(state, action) {
            state.isDeleteModalOpen = action.payload;
        },
        setPage(state, action) {
            state.page = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setTotal(state, action) {
            state.total = action.payload;
        },
        setSortBy(state, action) {
            state.sortBy = action.payload;
        },
        setPropertiesList(state, action) {
            state.propertiesList = action.payload;
        },
        setAgentsList(state, action) {
            state.agentsList = action.payload;
        }
    }
});

export const { actions: AgenciesReducerAction, name: AgenciesReducerName, reducer: AgenciesReducer } = agencies;
