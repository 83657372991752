import axiosServices from 'utils/axios';

export const GetAgenciesData = async (query?: string) => {
    try {
        const response = await axiosServices.get(query ? `/agencies/allAgencies?${query}` : '/agencies/allAgencies');
        return response;
    } catch (error) {
        return error;
    }
};

export const GetAllAgenciesList = async () => {
    try {
        const response = await axiosServices.get('/agent/agencies-list');
        return response;
    } catch (error) {
        return error;
    }
};

export const DownloadAgenciesData = async (query?: string) => {
    try {
        const response = await axiosServices.get(`/agencies/allAgencies?${query}`, {
            responseType: 'arraybuffer'
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const CreateAgency = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.post('/agencies/saveAgencies', data);
        return response;
    } catch (error) {
        return error;
    }
};

export const UpdateAgency = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.put(`/agencies/update-agencies-details/${data?._id}`, data);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetAgencyDetailsById = async (id: string) => {
    try {
        const response = await axiosServices.get(`/agencies/agencies-details/${id}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetPropertiesByAgencyId = async () => {
    try {
        const response = await axiosServices.get('/agencies/properties-list');
        return response;
    } catch (error) {
        return error;
    }
};

export const DeleteAgencyById = async (id: string) => {
    try {
        const response = await axiosServices.delete(`/agencies/delete-agencies/${id}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const UpdateAgencyPassword = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.post(`/agencies/change-password/${data?._id}`, data);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetAgentsByAgencyId = async (id: string) => {
    try {
        const response = await axiosServices.get(`/agencies/agent-list?agency_id=${id}`);
        return response;
    } catch (error) {
        return error;
    }
};
