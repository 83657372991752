// material-ui
import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const UpdateCardsSkeleton = () => (
    <Grid container alignItems="center" display="flex" gap="10px">
        <Skeleton variant="rectangular" sx={{ borderRadius: '10px' }} height={280} width={250} />
        <Skeleton variant="rectangular" sx={{ borderRadius: '10px' }} height={280} width={250} />
        <Skeleton variant="rectangular" sx={{ borderRadius: '10px' }} height={280} width={250} />
        <Skeleton variant="rectangular" sx={{ borderRadius: '10px' }} height={280} width={250} />
    </Grid>
);

export default UpdateCardsSkeleton;
