import { UserTypeEnum } from 'types/user-profile';
import { tabsOption } from 'views/properties/utils';
import { agentsclientsTabOption } from 'views/updates/utils';

export const getFormattedUserName = (name: string) => {
    return name.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '');
};

export const tabUserOption = ({ loggedInUserType }: any) => {
    switch (loggedInUserType) {
        case UserTypeEnum.ADMIN:
            return tabsOption;
        case UserTypeEnum.MANAGER:
            return tabsOption;
        case UserTypeEnum.AGENT:
            return agentsclientsTabOption;
        case UserTypeEnum.CLIENT:
            return agentsclientsTabOption;
    }
};

export const userTabPanel = (loggedInUserType: any) => {
    switch (loggedInUserType) {
        case UserTypeEnum.ADMIN:
            return true;
        case UserTypeEnum.MANAGER:
            return true;
        case UserTypeEnum.AGENT:
            return false;
        case UserTypeEnum.CLIENT:
            return false;
    }
};

export const ConvertToQueryString = (data: { [key: string]: any }) => {
    const filteredData = Object.keys(data)
        .filter((key) => data[key] !== null && data[key] !== undefined && data[key] !== '')
        .reduce((acc: any, key: string) => {
            acc[key] = data[key];
            return acc;
        }, {});

    const result = JSON.stringify(filteredData)
        .replace(/[{}]/g, '') // Remove curly braces
        .replace(/:/g, '=') // Remove curly braces
        .replace(/,/g, '&') // Replace commas with ampersands
        .replace(/"/g, '');

    return result;
};

export const DownloadFile = async (props: { [key: string]: any }) => {
    const { response, fileName, fileType } = props;
    if (response.status === 200) {
        const blobData = await response.data;
        const blob =
            (await blobData) &&
            new Blob([response?.data], {
                type: 'application/json'
            });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.${fileType}`;
        a.click();
        window.URL.revokeObjectURL(url);
    }
};
