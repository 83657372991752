import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'routes/route-guard/AuthGuard';
import ComingSoon from 'views/pages/maintenance/ComingSoon/ComingSoon2';
import AccessControlRoute from './AccessControlRoute';
import { UserTypeEnum } from 'types/user-profile';
import routeConstant from './routeConstant';
import ModalForm from 'views/properties/Components/ProfileFormsTabs/PropertyUnits/ModalForms/ModalForm';

// initial-screen
const InitialScreen = Loadable(lazy(() => import('views/initialScreen/InitialScreen')));

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard')));

//USER
const UserProfile = Loadable(lazy(() => import('views/Settings/View')));

//PROPERTY
const PropertiesDefault = Loadable(lazy(() => import('views/properties/Views/PropertyListing')));
const ProfileProfile = Loadable(lazy(() => import('views/properties/Views/PropertyProfile')));
const ProfileForm = Loadable(lazy(() => import('views/properties/Views/PropertyForms')));

const UpdatesListing = Loadable(lazy(() => import('views/updates/View/UpdatesListing')));
const UpdateProfile = Loadable(lazy(() => import('views/updates/View/UpdateProfile')));
const AccessDenied = Loadable(lazy(() => import('views/pages/authentication/AccessDenied')));
const UpdateForms = Loadable(lazy(() => import('views/updates/View/UpdateForms')));
const SupplierForms = Loadable(lazy(() => import('views/provider/Views/providerForms')));

const ClientsDefault = Loadable(lazy(() => import('views/clients/Views/ClientListing')));
const ClientProfile = Loadable(lazy(() => import('views/clients/Views/ClientProfile')));
const ClientForms = Loadable(lazy(() => import('views/clients/Views/ClientForms/')));

const DocumentsDefault = Loadable(lazy(() => import('views/documents/Views/DocumentsListing')));
const NewDocumentsForm = Loadable(lazy(() => import('views/documents/Views/DocumentsForm')));

const FinancialsDefault = Loadable(lazy(() => import('views/financials')));

const AgentsDefault = Loadable(lazy(() => import('views/agents/Views/AgentsListing')));
const AgentsProfile = Loadable(lazy(() => import('views/agents/Views/AgentProfile')));
const AgentForms = Loadable(lazy(() => import('views/agents/Views/AgentForms')));

const AgenciesDefault = Loadable(lazy(() => import('views/agencies/Views/AgenciesListing')));
const AgenciesForms = Loadable(lazy(() => import('views/agencies/Views/AgencyForms')));
const AgenciesProfile = Loadable(lazy(() => import('views/agencies/Views/AgenciesProfile')));

const ArchEngDefault = Loadable(lazy(() => import('views/provider/Views/providerListing')));
const ArchEngProfile = Loadable(lazy(() => import('views/provider/Views/providerProfile')));

const ManagersDefault = Loadable(lazy(() => import('views/managers/Views/managersListing')));
const ManagersProfile = Loadable(lazy(() => import('views/managers/Views/managerProfile')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: routeConstant.accessDenied,
            element: <AccessDenied />
        },
        {
            path: routeConstant.initial,
            element: <InitialScreen />
        },
        {
            path: routeConstant.dashboard,
            element: (
                <AccessControlRoute
                    allowedUserTypes={[
                        UserTypeEnum.ADMIN,
                        UserTypeEnum.MANAGER,
                        UserTypeEnum.CLIENT,
                        UserTypeEnum.AGENT,
                        UserTypeEnum.AGENCY
                    ]}
                >
                    <DashboardDefault />
                </AccessControlRoute>
            )
        },
        {
            path: routeConstant.userSettings,
            element: (
                <AccessControlRoute
                    allowedUserTypes={[
                        UserTypeEnum.ADMIN,
                        UserTypeEnum.MANAGER,
                        UserTypeEnum.CLIENT,
                        UserTypeEnum.AGENT,
                        UserTypeEnum.AGENCY
                    ]}
                >
                    <UserProfile />
                </AccessControlRoute>
            )
        },
        {
            path: routeConstant.updates,
            element: (
                <AccessControlRoute
                    allowedUserTypes={[
                        UserTypeEnum.ADMIN,
                        UserTypeEnum.MANAGER,
                        UserTypeEnum.CLIENT,
                        UserTypeEnum.AGENT,
                        UserTypeEnum.AGENCY
                    ]}
                >
                    <UpdatesListing />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.updatesProfile}/:id`,
            element: (
                <AccessControlRoute
                    allowedUserTypes={[
                        UserTypeEnum.ADMIN,
                        UserTypeEnum.MANAGER,
                        UserTypeEnum.CLIENT,
                        UserTypeEnum.AGENT,
                        UserTypeEnum.AGENCY
                    ]}
                >
                    <UpdateProfile />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.updatesForms}`,
            element: (
                <AccessControlRoute
                    allowedUserTypes={[
                        UserTypeEnum.ADMIN,
                        UserTypeEnum.MANAGER,
                        UserTypeEnum.CLIENT,
                        UserTypeEnum.AGENT,
                        UserTypeEnum.AGENCY
                    ]}
                >
                    <UpdateForms />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.updatesEdit}/:id`,
            element: (
                <AccessControlRoute
                    allowedUserTypes={[
                        UserTypeEnum.ADMIN,
                        UserTypeEnum.MANAGER,
                        UserTypeEnum.CLIENT,
                        UserTypeEnum.AGENT,
                        UserTypeEnum.AGENCY
                    ]}
                >
                    <UpdateForms />
                </AccessControlRoute>
            )
        },
        // Client
        {
            path: routeConstant.clients,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.ADMIN, UserTypeEnum.MANAGER, UserTypeEnum.AGENT, UserTypeEnum.AGENCY]}>
                    <ClientsDefault />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.clientsProfile}/:id`,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.ADMIN, UserTypeEnum.MANAGER, UserTypeEnum.AGENT, UserTypeEnum.AGENCY]}>
                    <ClientProfile />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.clientsEdit}/:id`,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.ADMIN, UserTypeEnum.MANAGER, UserTypeEnum.AGENT, UserTypeEnum.AGENCY]}>
                    <ClientForms />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.clientsForms}`,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.ADMIN, UserTypeEnum.MANAGER, UserTypeEnum.AGENT, UserTypeEnum.AGENCY]}>
                    <ClientForms />
                </AccessControlRoute>
            )
        },
        // Property
        {
            path: routeConstant.properties,
            element: (
                <AccessControlRoute
                    allowedUserTypes={[
                        UserTypeEnum.ADMIN,
                        UserTypeEnum.MANAGER,
                        UserTypeEnum.CLIENT,
                        UserTypeEnum.AGENT,
                        UserTypeEnum.AGENCY
                    ]}
                >
                    <PropertiesDefault />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.propertiesProfile}/:id`,
            element: (
                <AccessControlRoute
                    allowedUserTypes={[
                        UserTypeEnum.ADMIN,
                        UserTypeEnum.MANAGER,
                        UserTypeEnum.CLIENT,
                        UserTypeEnum.AGENT,
                        UserTypeEnum.AGENCY
                    ]}
                >
                    <ProfileProfile />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.propertiesEdit}/:id`,
            element: (
                <AccessControlRoute
                    allowedUserTypes={[
                        UserTypeEnum.ADMIN,
                        UserTypeEnum.MANAGER,
                        UserTypeEnum.CLIENT,
                        UserTypeEnum.AGENT,
                        UserTypeEnum.AGENCY
                    ]}
                >
                    <ProfileForm />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.propertiesForms}`,
            element: (
                <AccessControlRoute
                    allowedUserTypes={[
                        UserTypeEnum.ADMIN,
                        UserTypeEnum.MANAGER,
                        UserTypeEnum.CLIENT,
                        UserTypeEnum.AGENT,
                        UserTypeEnum.AGENCY
                    ]}
                >
                    <ProfileForm />
                </AccessControlRoute>
            )
        },
        {
            path: routeConstant.unitAdd,
            element: (
                <AccessControlRoute
                    allowedUserTypes={[
                        UserTypeEnum.ADMIN,
                        UserTypeEnum.MANAGER,
                        UserTypeEnum.CLIENT,
                        UserTypeEnum.AGENT,
                        UserTypeEnum.AGENCY
                    ]}
                >
                    <ModalForm />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.unitEdit}/:id`,
            element: (
                <AccessControlRoute
                    allowedUserTypes={[
                        UserTypeEnum.ADMIN,
                        UserTypeEnum.MANAGER,
                        UserTypeEnum.CLIENT,
                        UserTypeEnum.AGENT,
                        UserTypeEnum.AGENCY
                    ]}
                >
                    <ModalForm />
                </AccessControlRoute>
            )
        },
        // Docs
        {
            path: routeConstant.documents,
            element: (
                <AccessControlRoute
                    allowedUserTypes={[
                        UserTypeEnum.ADMIN,
                        UserTypeEnum.MANAGER,
                        UserTypeEnum.CLIENT,
                        UserTypeEnum.AGENT,
                        UserTypeEnum.AGENCY
                    ]}
                >
                    <DocumentsDefault />
                </AccessControlRoute>
            )
        },
        {
            path: routeConstant.newDocument,
            element: (
                <AccessControlRoute
                    allowedUserTypes={[
                        UserTypeEnum.ADMIN,
                        UserTypeEnum.MANAGER,
                        UserTypeEnum.CLIENT,
                        UserTypeEnum.AGENT,
                        UserTypeEnum.AGENCY
                    ]}
                >
                    <NewDocumentsForm />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.editDocument}/:id`,
            element: (
                <AccessControlRoute
                    allowedUserTypes={[
                        UserTypeEnum.ADMIN,
                        UserTypeEnum.MANAGER,
                        UserTypeEnum.CLIENT,
                        UserTypeEnum.AGENT,
                        UserTypeEnum.AGENCY
                    ]}
                >
                    <NewDocumentsForm />
                </AccessControlRoute>
            )
        },
        // Financial
        {
            path: routeConstant.financials,
            element: (
                <AccessControlRoute
                    allowedUserTypes={[
                        UserTypeEnum.ADMIN,
                        UserTypeEnum.MANAGER,
                        UserTypeEnum.CLIENT,
                        UserTypeEnum.AGENT,
                        UserTypeEnum.AGENCY
                    ]}
                >
                    <FinancialsDefault />
                </AccessControlRoute>
            )
        },
        // Agents
        {
            path: routeConstant.agents,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.ADMIN, UserTypeEnum.MANAGER]}>
                    <AgentsDefault />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.agentsProfile}/:id`,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.MANAGER, UserTypeEnum.ADMIN]}>
                    <AgentsProfile />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.agentsAdd}`,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.MANAGER, UserTypeEnum.ADMIN]}>
                    <AgentForms />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.agentsEdit}/:id`,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.MANAGER, UserTypeEnum.ADMIN]}>
                    <AgentForms />
                </AccessControlRoute>
            )
        },
        // Agencies
        {
            path: routeConstant.agencies,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.ADMIN, UserTypeEnum.MANAGER]}>
                    <AgenciesDefault />
                </AccessControlRoute>
            )
        },
        {
            path: routeConstant.agenciesAdd,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.ADMIN, UserTypeEnum.MANAGER]}>
                    <AgenciesForms />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.agenciesEdit}/:id`,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.ADMIN, UserTypeEnum.MANAGER]}>
                    <AgenciesForms />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.agenciesProfile}/:id`,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.ADMIN, UserTypeEnum.MANAGER]}>
                    <AgenciesProfile />
                </AccessControlRoute>
            )
        },
        // Architect and Engineers
        {
            path: routeConstant.provider,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.ADMIN, UserTypeEnum.MANAGER]}>
                    <ArchEngDefault />
                </AccessControlRoute>
            )
        },
        {
            path: routeConstant.providerAdd,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.ADMIN, UserTypeEnum.MANAGER]}>
                    <SupplierForms />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.providerEdit}/:id`,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.ADMIN, UserTypeEnum.MANAGER]}>
                    <SupplierForms />
                </AccessControlRoute>
            )
        },
        {
            path: `${routeConstant.providerProfile}/:id`,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.ADMIN, UserTypeEnum.MANAGER]}>
                    <ArchEngProfile />
                </AccessControlRoute>
            )
        },
        // Managers
        {
            path: routeConstant.managers,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.ADMIN]}>
                    <ManagersDefault />
                </AccessControlRoute>
            )
        },
        {
            path: routeConstant.managersProfile,
            element: (
                <AccessControlRoute allowedUserTypes={[UserTypeEnum.ADMIN]}>
                    <ManagersProfile />
                </AccessControlRoute>
            )
        },
        // Undetected Paths (temporary)
        {
            path: routeConstant.any,
            element: <ComingSoon />
        }
    ]
};

export default MainRoutes;
